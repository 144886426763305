import React, { useState } from 'react'

export const UserContext = React.createContext({});

export default function UserContextProvider({ children }) {

  const [auth, setAuth] = useState(false);
  const [lang, setLang] = useState('en');
  const server = "https://server.waseela.online/";
  const phone = "+34673855036";

  return (
    <UserContext.Provider value={{ server, phone, auth, setAuth, lang, setLang}}>
      {children}
    </UserContext.Provider>
  )
}